import React, { useEffect, useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Input from '../../../components/Input';
import InputCheckbox from '../../../components/Inputcheckbox';
import { useNavigate } from "react-router-dom";
import { AdsDropDown, BannerDropDown, CouponDropDown } from '../../../service/apiService';
import { CustomValidationError } from "../../../Helper/Helper";

const BannerMapCreate = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('admin_token');
  const [bannerDropdown, setBannerDropDown] = useState([]);
  const [adsDropdown, setAdsDropDown] = useState([]);
  const [couponDropdown, setCouponDropDown] = useState([]);
  const [BannerMapFormData, setBannerMapFormData] = useState({
    brand_id: '',
    is_active: 1,
    ad_id: '',
    coupon_id: ''
  });

  const [errors, setErrors] = useState({
    brand_id: '',
    ad_id: '',
    coupon_id: ''
  });

  const [validationErrors, setValidationErrors] = useState({
    brand_id: '',
    ad_id: '',
    coupon_id: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBannerMapFormData({ ...BannerMapFormData, [name]: value });
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setBannerMapFormData({
      ...BannerMapFormData,
      is_active: activeValue
    });
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (BannerMapFormData.brand_id.trim() === '') {
      formIsValid = false;
      errors.brand_id = 'Brand ID is required';
    }

    if (BannerMapFormData.ad_id.trim() === '' && BannerMapFormData.coupon_id.trim() === '') {
      formIsValid = false;
      errors.ad_id = 'Ad ID or Coupon ID is required';
      errors.coupon_id = 'Ad ID or Coupon ID is required';
    }

    setErrors(errors);
    return formIsValid;
  };

  useEffect(() => {
    if (token) {
      bannerMapDropDown();
      AdsDropDownList();
      couponDropDownList();
    }
  }, [token]);

  const bannerMapDropDown = async () => {
    const response = await BannerDropDown(token);
    setBannerDropDown(response.data);
  };

  const AdsDropDownList = async () => {
    const response = await AdsDropDown(token);
    setAdsDropDown(response.data);
  };

  const couponDropDownList = async () => {
    const response = await CouponDropDown(token);
    setCouponDropDown(response.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error('Please correct the errors in the form');
      return;
    }



    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const createResponse = await axios.post('https://folcon.voguefit.in/admin/create-BannerMap', BannerMapFormData, config);

      if (createResponse.data.status === "success") {
        toast.success('BannerMap created successfully!');
        setBannerMapFormData({
          brand_id: '',
          is_active: 1,
          ad_id: '',
          coupon_id: ''
        });
        navigate('/admin/BannerMap-list');
      } else {
        toast.error('BannerMap creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating BannerMap:', error);
      toast.error('BannerMap creation failed. Please try again.');
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>BannerMap Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">BannerMap</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <label htmlFor="brand" className="form-label">Banner Name</label>
                    <select
                      id="brand"
                      className="form-select"
                      name="brand_id"
                      value={BannerMapFormData.brand_id}
                      onChange={handleChange}
                    >
                      {!bannerDropdown ? (
                        <option value="">No Data found</option>
                      ) : (
                        <>
                          <option value="">Select Banner Name</option>
                          {bannerDropdown.map((banner) => (
                            <option key={banner.id} value={banner.id}>
                              {banner.banner_name.toUpperCase()}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                    {errors.brand_id && <CustomValidationError error={errors.brand_id} />}
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="ad" className="form-label">Ads Name</label>
                    <select
                      id="ad"
                      className="form-select"
                      name="ad_id"
                      value={BannerMapFormData.ad_id}
                      onChange={handleChange}
                    >
                      {!adsDropdown ? (
                        <option value="">No Data found</option>
                      ) : (
                        <>
                          <option value="">Select Ads Name</option>
                          {adsDropdown.map((ads) => (
                            <option key={ads.id} value={ads.id}>
                              {ads.name.toUpperCase()}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                    {errors.ad_id && <CustomValidationError error={errors.ad_id} />}
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="coupon" className="form-label">Coupon Name</label>
                    <select
                      id="coupon"
                      className="form-select"
                      name="coupon_id"
                      value={BannerMapFormData.coupon_id}
                      onChange={handleChange}
                    >
                      {!couponDropdown ? (
                        <option value="">No Data found</option>
                      ) : (
                        <>
                          <option value="">Select Coupon Name</option>
                          {couponDropdown.map((coupon) => (
                            <option key={coupon.id} value={coupon.id}>
                              {coupon.name.toUpperCase()}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                    {errors.coupon_id && <CustomValidationError error={errors.coupon_id} />}
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-8">
                      <InputCheckbox
                        type="checkbox"
                        id="toggle"
                        checked={BannerMapFormData.is_active === 1}
                        onChange={handleToggleChange}
                      />
                      <label htmlFor="toggle" className="form-check-label">
                        {BannerMapFormData.is_active === 1 ? "Active" : "Inactive"}
                      </label>
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BannerMapCreate;
