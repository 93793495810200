import React, { useEffect, useState } from "react";
// import UploadImage from '../../../assets/images/upload.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

const CreatChatbotCategory = () => {
  const [PagesformData, setPagesFormData] = useState({
    name: '',
  });

  var navigate = useNavigate();

  const token = localStorage.getItem("admin_token");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setPagesFormData({
      ...PagesformData,
      [id]: value,

    });
  };

  const [validationErrors, setValidationErrors] = useState({
    name: "",
  });


  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    //alert(token);
    const csrfToken = localStorage.getItem('csrfToken');
    const errors = {};
    if (!PagesformData.name) {
      errors.name = "Name is required";
    }

    setValidationErrors(errors);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      if (!PagesformData.name) {
        toast.error('Please Fill The Form Data');
        return;
      }
      const createPayload = {
        ...PagesformData,
        name: PagesformData.name,
      };

      const createResponse = await axios.post('https://folcon.voguefit.in/admin/create-chatbot-category', createPayload, config);

      if (createResponse.data.status === "success") {

        toast.success('Chatbot Category created successfully!');
        navigate('/admin/chatbot/category-list');
        setPagesFormData({
          name: '',

        });
      } else {
        toast.error('Chatbot Category failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating Chatbot Category:', error);
      toast.error('Chatbot Category creation failed. Please try again.');
    }
  };


  return (
    <main id="main" className="main">
      <AdminHeader />
      <SideBar />
      <div className="pagetitle">
        <h1>Chatbot Category Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Chatbot</li>
            <li className="breadcrumb-item active">Category</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-4">
                      <input id="name" type="text" value={PagesformData.name} placeholder="Type here" onChange={handleChange} className="form-control" />
                      {validationErrors.name && <div className="text-danger">{validationErrors.name}</div>}

                    </div>
                  </div>


                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>

      </section>
      <AdminFooter />
    </main>

  )



}


export default CreatChatbotCategory