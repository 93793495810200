import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEllipsis, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

const MediaList = () => {
    const [uploadedImages, setUploadedImages] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 12; // Number of items per page
    const appBaseUrl = "https://folcon.voguefit.in/api/files/";
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-CSRF-Token': csrfToken,
        },
    };
    const navigate = useNavigate();
    useEffect(() => {
        fetchUploadedImages();
    }, []);

    const fetchUploadedImages = async () => {
        try {
            const response = await axios.get('https://folcon.voguefit.in/admin/media-list', config);
            if (response.status !== 200 || !response.data || response.data.status !== 'success') {
                throw new Error('Failed to fetch uploaded images');
            }
            setUploadedImages(response.data.data);
        } catch (error) {
            console.error('Error fetching uploaded images:', error);
        }
    };

    const copyToClipboard = (text) => {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const handleCopyLink = (imagePath) => {
        const imageUrl = appBaseUrl + imagePath;
        copyToClipboard(imageUrl);
        toast.success('Link copied!');
    };
    const pageCount = Math.ceil((uploadedImages?.length ?? 0) / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const displayImages = (uploadedImages ?? [])
        .slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)
        .map((image, index) => (
            <div className="card-design col-lg-3 col-md-4 col-sm-6" key={index}>
                <div className="thumbnail card-border-design position-relative">
                    <img src={`${appBaseUrl}${image}`} alt={`Image ${index}`} />
                    <div className="card-dropdown">
                        <span className="dropdown-icon" data-bs-toggle="dropdown">
                            <FontAwesomeIcon icon={faEllipsis} />
                        </span>
                        <div className="dropdown-menu dropdown-menu-right media-dropdown" aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item copy-link image-drop-down-menu dropdown-menus-design" onClick={() => handleCopyLink(image)}>
                                <FontAwesomeIcon icon={faCopy} />
                                Copy Link
                            </button>
                            {/* <a className="dropdown-item delete-image image-drop-down-menu dropdown-menus-design" href="/">
                                <FontAwesomeIcon icon={faTrash} />
                                Delete
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        ));

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Media List</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Media</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>
            </div>
            <section className="section">
                <div className="col-sm-12" id="media-manager-container">
                    <div className="card">
                        <div className="card-header d-md-flex justify-content-between align-items-center">
                            <h5 className="mb-0 h6">
                                All Media
                            </h5>
                            <a onClick={() => navigate('/admin/media-create')} className="btn m-0 btn-outline-primary custom-btn-small add-files-btn">
                                <FontAwesomeIcon icon={faPlus} />Add Media
                            </a>
                        </div>
                        <div className="card-block">
                            <div className="card-arrangement-design">
                                <div className="row">
                                    {displayImages}
                                </div>
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MediaList;
