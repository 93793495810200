import React, { useState, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import '../src/assets/css/main.css';
import '../src/assets/css/customeAdmin.css';
import { ToastContainer } from 'react-toastify';
import CreateClassification from './pages/views/Classification/CreateClassification';
import ClassificationList from './pages/views/Classification/ClassificationList';
import Section from './pages/views/Section/Section';
import CreateCampaign from './pages/views/Campaign/Create';
import CampaignList from './pages/views/Campaign/List';
import PageList from './pages/views/pages/List';
import OrderDetails from './pages/views/Orders/Orderdetails';

import ProductClassificationForm from "./components/Product/ProductClassificationForm";
import ProductCampaignForm from "./components/Product/ProductCampaignForm";
import FaqCategoryCreate from './pages/views/Faqcategory/Create';
import FaqCategoryList from './pages/views/Faqcategory/List';

import ChatbotcategoryCreate from './pages/views/chatbot_category/Create';
import ChatbotcategoryList from './pages/views/chatbot_category/List';

import Chatbotenquirycreate from './pages/views/Chatbot_enquiry/Create';
import Chatbotenquirylist from './pages/views/Chatbot_enquiry/List';

import ChatbotQustioncreate from './pages/views/Chatbot_questionans/Create';
import ChatbotQustionlist from './pages/views/Chatbot_questionans/List';



// hide temporary for texting purpose 
// const AdminLogin = lazy(() => import('./pages/views/AdminLogin'));
// const Dashboard = lazy(() => import('./components/Dashboard'));
// const Brand = lazy(() => import('./components/Brands/Brand'));
// const BrandCreate = lazy(() => import('./components/Brands/BrandCreate'));
// const ProductCreate = lazy(() => import('./components/Product/ProductCreate'));
// const ProductList = lazy(() => import('./components/Product/Product'));
// const Categories = lazy(() => import('./components/Categories/Categoty'));
// const SellerCreate = lazy(() => import('./components/Seller/Sellercreate'));
// const SellerList = lazy(() => import('./components/Seller/Seller'));
// const Variants = lazy(() => import('./components/Variants/Variants'));
// const VariantsCreate = lazy(() => import('./components/Variants/Variantscreate'));
// const Alladmin = lazy(() => import('./components/Admin/Alladmin'));
// const AdminCreate = lazy(() => import('./components/Admin/AdminCreate'));
// const CategoryCreate = lazy(() => import('./pages/views/Category/CategoryAdd'));
// const Taxcreate = lazy(() => import('./components/Tax/CreateTax'));
// const TaxList = lazy(() => import('./components/Tax/Taxlist'));
// const CouponCreate = lazy(() => import('./components/Coupon/Createcoupon'));
// const CouponList = lazy(() => import('./components/Coupon/Couponlist'));
// const DiscountCreate = lazy(() => import('./components/Discount/Discountcreate'));
// const DiscountList = lazy(() => import('./components/Discount/Discountlist'));
// const Productpage = lazy(() => import('./components/Appearance/Product'));
// const Adspage = lazy(() => import('./components/Appearance/Ads'));
// const Bannerpage = lazy(() => import('./components/Appearance/Home/Banner'));
// const Footerpage = lazy(() => import('./components/Appearance/Home/Footer'));
// const Sliderpage = lazy(() => import('./components/Appearance/Home/Slider'));
// const Blogpage = lazy(() => import('./components/Appearance/Pages/Blogs'));
// const Contactpage = lazy(() => import('./components/Appearance/Pages/Contact'));
// const Faqpage = lazy(() => import('./components/Appearance/Pages/Faq'));
// const Policypage = lazy(() => import('./components/Appearance/Pages/Policy'));
// const Termspage = lazy(() => import('./components/Appearance/Pages/Terms'));
// const Aboutpage = lazy(() => import('./components/Appearance/Pages/about'));
// const Media = lazy(() => import('./components/Media/Media'));
// const MediaStore = lazy(() => import('./components/Media/MediaStore'));
// const Bannertypecreate = lazy(() => import('./components/Settings/Bannertype'));
// const Bannertypelist = lazy(() => import('./components/Settings/BannerTypeList'));
// const HomePage = lazy(() => import('./pages/views/HomePage'));
// const ProductEdit = lazy(() => import('./pages/views/product/ProductEdit'));
// const ProductView = lazy(() => import('./pages/views/product/ProductView'));
// const AllOrders = lazy(() => import('./components/Orders/AllOrders'));
// const AdsList = lazy(() => import('./components/Appearance/AdsList'));
// const ProductLists = lazy(() => import('./components/Appearance/ProductList'));
// const BannerLists = lazy(() => import('./components/Appearance/Home/BaannerList'));
// const FooterLists = lazy(() => import('./components/Appearance/Home/FooterList'));
// const SliderLists = lazy(() => import('./components/Appearance/Home/SliderList'));
// const AboutLists = lazy(() => import('./components/Appearance/Pages/Aboutlist'));
// const BlogLists = lazy(() => import('./components/Appearance/Pages/BlogsList'));
// const ContactLists = lazy(() => import('./components/Appearance/Pages/ContactList'));
// const FaqLists = lazy(() => import('./components/Appearance/Pages/FaqList'));
// const PolicyLists = lazy(() => import('./components/Appearance/Pages/PolicyList'));
// const TermLists = lazy(() => import('./components/Appearance/Pages/TermList'));
// const MainBanner = lazy(() => import('./components/Appearance/Banner/Create'));
// const MainBannerList = lazy(() => import('./components/Appearance/Banner/List'));
// const BannerMap = lazy(() => import('./components/BannerMap/BannerMap'));
// const CustomCss = lazy(() => import('./components/Custom-css/CustomCss'));
// const NotFound = lazy(() => import('./pages/views/Admin/NotFound'));
// Campaign



// Import all components directly
import AdminLogin from './pages/views/AdminLogin';
import Dashboard from './components/Dashboard';
import Brand from './components/Brands/Brand';
import BrandCreate from './components/Brands/BrandCreate';
import ProductCreate from './components/Product/ProductCreate';
import ProductList from './components/Product/Product';
import Categories from './components/Categories/Categoty';
import SellerCreate from './components/Seller/Sellercreate';
import SellerList from './components/Seller/Seller';
import Variants from './components/Variants/Variants';
import VariantsCreate from './components/Variants/Variantscreate';
import Alladmin from './components/Admin/Alladmin';
import AdminCreate from './components/Admin/AdminCreate';
import CategoryCreate from './pages/views/Category/CategoryAdd';
import Taxcreate from './components/Tax/CreateTax';
import TaxList from './components/Tax/Taxlist';
import CouponCreate from './components/Coupon/Createcoupon';
import CouponList from './components/Coupon/Couponlist';
import DiscountCreate from './components/Discount/Discountcreate';
import DiscountList from './components/Discount/Discountlist';
import Productpage from './components/Appearance/Product';
import Adspage from './components/Appearance/Ads';
import Bannerpage from './components/Appearance/Home/Banner';
import Footerpage from './components/Appearance/Home/Footer';
import Sliderpage from './components/Appearance/Home/Slider';
import Blogpage from './components/Appearance/Pages/Blogs';
import Contactpage from './components/Appearance/Pages/Contact';
import Faqpage from './components/Appearance/Pages/Faq';
import Policypage from './components/Appearance/Pages/Policy';
import Termspage from './components/Appearance/Pages/Terms';
import Aboutpage from './components/Appearance/Pages/about';
import Media from './components/Media/Media';
import MediaStore from './components/Media/MediaStore';
import Bannertypecreate from './components/Settings/Bannertype';
import Bannertypelist from './components/Settings/BannerTypeList';
import HomePage from './pages/views/HomePage';
import ProductEdit from './pages/views/product/ProductEdit';
import ProductView from './pages/views/product/ProductView';
import AllOrders from './components/Orders/AllOrders';
import AdsList from './components/Appearance/AdsList';
import ProductLists from './components/Appearance/ProductList';
import BannerLists from './components/Appearance/Home/BaannerList';
import FooterLists from './components/Appearance/Home/FooterList';
import SliderLists from './components/Appearance/Home/SliderList';
import AboutLists from './components/Appearance/Pages/Aboutlist';
import BlogLists from './components/Appearance/Pages/BlogsList';
import ContactLists from './components/Appearance/Pages/ContactList';
import FaqLists from './components/Appearance/Pages/FaqList';
import PolicyLists from './components/Appearance/Pages/PolicyList';
import TermLists from './components/Appearance/Pages/TermList';
import MainBanner from './components/Appearance/Banner/Create';
import MainBannerList from './components/Appearance/Banner/List';
import BannerMap from './components/BannerMap/BannerMap';
import CustomCss from './components/Custom-css/CustomCss';
import NotFound from './pages/views/Admin/NotFound';
import BlogCategoryCreate from './pages/views/Blogs/BlogCategory/BlogCategoryCreate';
import BlogCategoryList from './pages/views/Blogs/BlogCategory/BlogCategoryList';
import BlogCreate from './pages/views/Blogs/Blog/BlogCreate';
import BlogList from './pages/views/Blogs/Blog/BlogList';
import Setting from './components/Settings/Setting_list';
import BlogsTagCreate from './pages/views/Blogs/BlogTag/BlogTagCreate';
import BlogsTagList from './pages/views/Blogs/BlogTag/BlogTagList';
import MainSubListCreate from './components/SubPages/MainSubListCreate';
import MainSubListPage from './components/SubPages/MainSubListPage';
import AppAboutUs from './pages/views/AppAboutUs/AppAboutUs';
import Access from './pages/views/AccessPage/Access';
// import CssPage from './pages/views/CustomCss/Csspage';


function App() {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(
    localStorage.getItem('isAdminAuthenticated') === 'true'
  );
  const [productKeyState, setProductKeyState] = useState(
    localStorage.getItem('productKeyState') === 'false'
  )

  useEffect(() => {
    localStorage.setItem('isAdminAuthenticated', isAdminAuthenticated);
  }, [isAdminAuthenticated]);

  localStorage.setItem('productKeyState', productKeyState);

  useEffect(() => {
    const adminToken = localStorage.getItem('admin_token');
    setIsAdminAuthenticated(!!adminToken);
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Navigate to="/admin" replace />} />
        <Route path="/admin" element={<AdminLogin setIsAdminAuthenticated={setIsAdminAuthenticated} setProductKeyState={setProductKeyState} />} />
        <Route path="/admin/*"
          element={isAdminAuthenticated ? <AdminRoutes />
            : <Navigate to="/" replace />} />
        {productKeyState && <Route path="/product-key" element={<Access setProductKeyState={setProductKeyState} />} />}
      </Routes>
    </BrowserRouter>
  );
}

function AdminRoutes() {
  return (
    <Routes>
      <Route path="custom-css" element={<CustomCss />} />
      <Route path="dashboard" element={<Dashboard />} />

      {/* Product Routes */}
      <Route path="product-create" element={<ProductCreate />} />
      <Route path="product-list" element={<ProductList />} />
      <Route path="product-edit" element={<ProductEdit />} />
      <Route path="product-view" element={<ProductView />} />

      {/* Brand Routes */}
      <Route path="brands" element={<Brand />} />
      <Route path="brands/create" element={<BrandCreate />} />

      {/* Category Routes */}
      <Route path="categories" element={<Categories />} />
      <Route path="categories/create" element={<CategoryCreate />} />

      {/* Seller Routes */}
      <Route path="seller" element={<SellerList />} />
      <Route path="seller-create" element={<SellerCreate />} />

      {/* Variant Routes */}
      <Route path="variants" element={<Variants />} />
      <Route path="variants-create" element={<VariantsCreate />} />
      {/* Admin Pages */}


      <Route path="list" element={<PageList />} />


      {/* Admin Routes */}
      <Route path="admin" element={<Alladmin />} />
      <Route path="admin-create" element={<AdminCreate />} />

      {/* Media Routes */}
      <Route path="media" element={<Media />} />
      <Route path="media-create" element={<MediaStore />} />

      {/* Tax Routes */}
      <Route path="tax-create" element={<Taxcreate />} />
      <Route path="tax-list" element={<TaxList />} />

      <Route path="order_details" element={<OrderDetails />} />


      {/* Coupon Routes */}
      <Route path="coupon-create" element={<CouponCreate />} />
      <Route path="coupon-list" element={<CouponList />} />

      {/* Discount Routes */}
      <Route path="discount-create" element={<DiscountCreate />} />
      <Route path="discount-list" element={<DiscountList />} />

      {/* Appearance Routes */}
      <Route path="appearance-product" element={<Productpage />} />
      <Route path="appearance-productlist" element={<ProductLists />} />
      <Route path="appearance-ads" element={<Adspage />} />

      <Route path="appearance-subpage" element={<MainSubListPage />} />
      <Route path='appearance-subpageCreate' element={<MainSubListCreate />} />


      <Route path="appearance-adslist" element={<AdsList />} />
      <Route path="appearance-banner" element={<Bannerpage />} />
      <Route path="appearance-bannerlist" element={<BannerLists />} />
      <Route path="appearance-footer" element={<Footerpage />} />
      <Route path="appearance-footerlist" element={<FooterLists />} />
      <Route path="appearance-slider" element={<Sliderpage />} />
      <Route path="appearance-sliderlist" element={<SliderLists />} />
      <Route path="appearance-blog" element={<Blogpage />} />
      <Route path="appearance-contact" element={<Contactpage />} />
      <Route path="appearance-faq" element={<Faqpage />} />

      <Route path="appearance-policy" element={<Policypage />} />
      <Route path="appearance-terms" element={<Termspage />} />
      <Route path="appearance-about" element={<Aboutpage />} />
      <Route path="appearance-aboutlist" element={<AboutLists />} />
      <Route path="appearance-bloglist" element={<BlogLists />} />
      <Route path="appearance-contactlist" element={<ContactLists />} />
      <Route path="appearance-policylist" element={<PolicyLists />} />
      <Route path="appearance-termslist" element={<TermLists />} />
      <Route path="appearance-faqlist" element={<FaqLists />} />


      {/* chatbot */}
      <Route path="chatbot/category-create" element={<ChatbotcategoryCreate />} />
      <Route path="chatbot/category-list" element={<ChatbotcategoryList />} />
      <Route path="chatbot/enquiry-create" element={<Chatbotenquirycreate />} />
      <Route path="chatbot/enquirylist" element={<Chatbotenquirylist />} />

      <Route path="chatbot/question" element={<ChatbotQustioncreate />} />
      <Route path="chatbot/questionall" element={<ChatbotQustionlist />} />


      {/* Blogs Routes */}
      <Route path="blogs/blog/create" element={<BlogCreate />} />
      <Route path="blogs/blog/list" element={<BlogList />} />
      <Route path="blogs/blogCategory/create" element={<BlogCategoryCreate />} />
      <Route path="blogs/blogCategory/list" element={<BlogCategoryList />} />
      <Route path="blogs/blogTags/create" element={<BlogsTagCreate />} />
      <Route path="blogs/blogTags/list" element={<BlogsTagList />} />


      {/* Banner Routes */}
      <Route path="banner-type" element={<Bannertypecreate />} />
      <Route path="banner-type-list" element={<Bannertypelist />} />
      <Route path="banner-create" element={<MainBanner />} />
      <Route path="banner-list" element={<MainBannerList />} />
      <Route path="banner-map-create" element={<BannerMap />} />
      <Route path="banner-map-list" element={<TaxList />} />

      {/* Orders Routes */}
      <Route path="all-orders" element={<AllOrders />} />

      {/* Classification Routes */}
      <Route path="classification-create" element={<CreateClassification />} />
      <Route path="classifications" element={<ClassificationList />} />

      {/* Campaign Routes */}
      <Route path="campaign-create" element={<CreateCampaign />} />
      <Route path="campaigns" element={<CampaignList />} />



      {/* Faq Category Routes */}
      <Route path="faqCategory-create" element={<FaqCategoryCreate />} />
      <Route path="faqCategories" element={<FaqCategoryList />} />



      <Route path="productclassification-create" element={< ProductClassificationForm />} />
      <Route path="productcampaign-create" element={<ProductCampaignForm />} />

      {/* Section Routes */}
      <Route path="sections" element={<Section />} />

      <Route path="custom-css" element={<CustomCss />} />
      <Route path="home" element={<HomePage />} />
      <Route path="*" element={<NotFound />} />

      {/* Settings */}
      <Route path="setting-list" element={<Setting />} />

      {/* APP ABOUT US */}
      <Route path='app/about-us' element={<AppAboutUs />} />
    </Routes>
  );
}

export default App;
