import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import UploadImage from '../../../assets/images/upload.png';
import ReactPaginate from 'react-paginate';
import SingleImageSelect from '../../../components/Media/SingleImageSelect';
import { MediaList } from "../../../service/apiService";
import { useNavigate } from 'react-router-dom';
import noImage from "../../../assets/images/noimage.jpg";

const VariantsList = () => {
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState('');
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [Variants, SetVariants] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVariantsId, setSelectedVarientsId] = useState(null);
  const [VariantsformData, setVariantsFormData] = useState({
    name: '',
    slug: '',
  });
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [variantImage, setVariantsImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const AppUrl = "https://folcon.voguefit.in/api/files/";
  const [deletedImage, setDeletedImage] = useState(false);

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setVariantsFormData({
      ...VariantsformData,
      is_active: activeValue
    });
  };

  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, '-');
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setVariantsFormData(prevState => ({
      ...prevState,
      [id]: value,
      slug: generateSlug(value)
    }));
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
    setVariantsImage(null);
    setDeletedImage(true);
  };

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter(i => i !== index));
      }
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setVariantsImage(imageUrl[index]);
      closeMediaModal();
    }
  };

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const [mediaModalIsOpen, setMediaModalIsOpen] = useState(false);

  const openMediaModal = () => {
    setMediaModalIsOpen(true);
  };

  const closeMediaModal = () => {
    setMediaModalIsOpen(false);
  };

  useEffect(() => {
    fetchData();

    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(imageUrls);
      });
    }
  }, [token]);

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(Variants.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentVariants = Variants.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      const response = await fetch('https://folcon.voguefit.in/admin/all-variant-label', config);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();

      let VariantsData = [];

      if (Array.isArray(responseData)) {
        VariantsData = responseData;
      } else if (Array.isArray(responseData.data)) {
        VariantsData = responseData.data;
      } else {
        throw new Error('Data is not in the expected format');
      }
      SetVariants(VariantsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      if (!VariantsformData.name || !VariantsformData.slug) {
        toast.error('Please Fill The Form Data');
        return;
      }

      const createPayload = {
        ...VariantsformData,
        image_path: variantImage ? 'files/' + variantImage : null,
        variant_label_name: VariantsformData.name,
        variant_label_id: selectedVariantsId,
      };

      if (deletedImage) {
        createPayload.image_path = null;
      } else if (!variantImage) {
        createPayload.image_path = VariantsformData.variant_image_path || null;
      }

      const createResponse = await axios.post('https://folcon.voguefit.in/admin/update-variant-label', createPayload, config);

      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        setSelectedFile(null);
        toast.success('Variants Updated successfully!');
        fetchData();
        setVariantsFormData({
          name: '',
          slug: '',
          is_active: 0,
          variant_label_id: ''
        });
        setDeletedImage(false); // Reset the deletedImage flag
      } else {
        toast.error('Variants creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating variant:', error);
      toast.error('Variants creation failed. Please try again.');
    }
    closeEditModal();
  };

  const handleEdit = async (VarientsId) => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };
      const response = await axios.get(`https://folcon.voguefit.in/admin/get-variant-label/${VarientsId}`, config);
      const responseData = response.data;
      setVariantsFormData({
        name: responseData.data.variant_label_name,
        slug: responseData.data.variant_slug,
        variant_label_id: responseData.data.variant_label_id,
        is_active: responseData.data.is_active,
        variant_image_path: responseData.data.variant_image_path
      });
      setSelectedVarientsId(VarientsId);
      const previewImageUrl = responseData.data.variant_image_path ? appBaseUrl.concat(responseData.data.variant_image_path) : null;
      setPreviewImage(previewImageUrl);
      openEditModal();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const header = (
    <div className="table-header">
      <div>
        <h3>Variants</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
      <div className="create-category-button">
        <button onClick={() => navigate('/admin/variants-create')} className="btn btn-primary">Create Variants</button>
      </div>
    </div>
  );

  const columns = [
    { field: 'variant_label_id', header: 'ID' },
    { field: 'variant_label_name', header: 'Variants Name' },
    { field: 'variant_slug', header: 'Slug' },
    {
      field: 'variant_image_path',
      header: 'Image',
      body: (rowData) => (
        <img
          src={rowData.variant_image_path ? `${appBaseUrl}${rowData.variant_image_path}` : noImage}
          width={100}
          height={100}
          alt="variant"
        />
      )
    },
    { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
    // {
    //   field: 'action', header: 'Action', body: (rowData) => (
    //     <div className="text-end">
    //       <div className="dropdown">
    //         <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
    //           <FontAwesomeIcon icon={faStreetView} />
    //         </a>
    //         <div className="dropdown-menu">
    //           <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.variant_label_id)} >Edit info</a>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }
  ];

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Variants List</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Variants</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <ReactDataTable
          data={currentVariants}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          header={header}
          emptyMessage="No Variants records found"
          columns={columns}
        />
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </section>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Brand Info"
        style={{
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            position: 'relative',
            backgroundColor: '#fff',
            borderRadius: '10px',
            padding: '20px',
            overflowY: 'auto',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        <div className='container' style={{ width: '900px' }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Variants</h2>
            </div>
            <div className="col-auto"></div>
            <button onClick={closeEditModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
            </button>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="form-control"
                  id="name"
                  value={VariantsformData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="slug" className="form-label">
                  Slug
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="form-control readonly"
                  id="slug"
                  value={VariantsformData.slug}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label htmlFor="toggle" className="form-label">
                  Status
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggle"
                    checked={VariantsformData.is_active === 1}
                    onChange={handleToggleChange}
                  />
                </div>
              </div>
              <div className="mb-4">
                <div className="card-header">
                  <h4>Preview</h4>
                  <br />
                  {previewImage ? (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />
                      <button
                        type="button"
                        onClick={handleDeletePreview}
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  ) : (
                    <img src={UploadImage} alt="Alt" />
                  )}
                  <br />
                  <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={openMediaModal}>
                    Select Image
                  </button>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">Update</button>
            </form>
          </div>
        </div>
        <SingleImageSelect
          isOpen={mediaModalIsOpen}
          handleClose={closeMediaModal}
          handleImageClick={handleImageClick}
          selectedImages={selectedImages}
          imageUrl={imageUrl}
          token={token}
          setImageUrl={setImageUrl}
        />
      </Modal>
    </main>
  )
}

export default VariantsList;
