
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faImages, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import UploadImage from "../../assets/images/defimg.jpeg";
import InputCheckbox from "../Inputcheckbox";
import Input from "../Input";
import SingleImageSelect from "../Media/SingleImageSelect";
import {
  BrandDropDown,
  CategoriesDropDown,
  DiscountDropDown,
  MediaList,
  TaxDropDown,
} from "../../service/apiService";
import { ValidateBrand, ValidateCategory, ValidatePrice, ValidateProductName, ValidateQuantity, ValidateSku, ValidateTax, ValidateUnit, ValidateVariant, ValidateWeight, returnDate, validateAddress, validateSlug, validateStock } from "../../validation/CustomValidation";
import { CustomValidationError } from "../../Helper/Helper";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import Select from 'react-select';
import folder from "../../assets/images/image.png"


const ProductForm = ({ onSuccess }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const AppUrl = "https://folcon.voguefit.in/api/files/";
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState('');
  const [specification, setSpecification] = useState('');


  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data?.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });

      CategoryDropdownList();
      BrandDropDownList();
      TaxDropDownList();
      DiscountDropDownList();
    }
  }, [token]);
  const [productformData, setProductFormData] = useState({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    price: "",
    discount: "",
    tax: "",
    category: "",
    brand: "",
    slug: "",
    is_have_variant: "1",
    unit: "5",
    weight: "5",
    returning_date: "5",
    is_active: 1,
  });
  const [validationErrors, setValidationErrors] = useState({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    price: "",
    discount: "",
    tax: "",
    category: "",
    brand: "",
    slug: "",
    is_active: 1,
  });


  const generateSlug = (name) => {
    return name
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-');
  };

  const handleChange = (e) => {
    const { target: { id, value, dataset } = {} } = e;
    const capitalizeWords = (text) => {
      return text.replace(/([^\w\s])/gi, (match) => match.replace(/\s+/g, '')).replace(/\b\w+\b/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    };

    const transformedValue = (id === "product_name") ? capitalizeWords(value) : value;
    let updatedFormData = {
      ...productformData,
      [id]: value,
    };

    if (id === 'product_name') {
      updatedFormData = {
        ...updatedFormData,
        [id]: transformedValue,
        slug: generateSlug(value),
      };
    }
    setProductFormData(updatedFormData);
  };


  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setProductFormData({
      ...productformData,
      is_active: activeValue
    });
  };
  const handlePreOrderChange = (e) => {
    const isChecked = e.target.checked;
    const preOrderValue = isChecked ? 1 : 0;

    setProductFormData({
      ...productformData,
      is_pre_order: preOrderValue,
      quantity: isChecked ? 0 : productformData.quantity,
    });
  };

  const CategoryDropdownList = async () => {
    const CategoriesDropDownList = await CategoriesDropDown(token);
    setCategoryList(CategoriesDropDownList.data);
  };
  const BrandDropDownList = async () => {
    const BrandDropDownList = await BrandDropDown(token);
    setBrandList(BrandDropDownList.data);
  };
  const TaxDropDownList = async () => {
    const TaxDropDownList = await TaxDropDown(token);
    setTaxList(TaxDropDownList.data);
  }
  const DiscountDropDownList = async () => {
    const DiscountDropDownList = await DiscountDropDown(token);
    setDiscountList(DiscountDropDownList.data);
  }

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter((i) => i !== index));
      }

    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      closeModal();
    }
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');
    const productNameError = ValidateProductName(productformData.product_name);
    const skuError = ValidateSku(productformData.sku);
    const priceError = ValidatePrice(productformData.price);
    const taxError = ValidateTax(productformData.tax);
    const brandError = ValidateBrand(productformData.brand);
    const categoryError = ValidateCategory(productformData.category);
    const unitError = ValidateUnit(productformData.unit);
    const varientError = ValidateVariant(productformData.is_have_variant);
    const weightError = ValidateWeight(productformData.weight);
    const slugError = validateSlug(productformData.slug);
    const stockCountError = validateStock(productformData.minimum_stock_warning);
    const returningDateError = returnDate(productformData.returning_date);
    setValidationErrors({
      product_name: productNameError,
      sku: skuError,
      price: priceError,
      tax: taxError,
      brand: brandError,
      category: categoryError,
      // quantity: quantityError,
      minimum_stock_warning: stockCountError,
      unit: 1,
      is_have_variant: 1,
      weight: 1,
      slug: slugError,
      returning_date: 5
    });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };
    try {

      if (selectedFile) {
        const formDataToSend = new FormData();
        formDataToSend.append('file_name', selectedFile);

        const productuploadResponse = await axios.post('https://folcon.voguefit.in/admin/media-upload', formDataToSend, config);

        if (productuploadResponse.data && productuploadResponse.data.data) {
          imageUrl = productuploadResponse.data.data;
        } else {
          toast.error('Error uploading image. Please try again.');
          return;
        }
      }
      const quantity = parseInt(productformData.quantity, 10);
      const minimum_stock_warning = parseInt(productformData.minimum_stock_warning, 10);
      const price = parseFloat(productformData.price);
      const discount = parseInt(productformData.discount, 10);
      const tax = parseInt(productformData.tax, 10);
      // temporty data
      const category = parseInt(productformData.category, 10);
      const brand = parseInt(productformData.brand, 10);


      const createPayload = {
        ...productformData,
        image_path: previewImage?.split('/').pop(),
        product_name: productformData.product_name,
        quantity: quantity,
        minimum_stock_warning: minimum_stock_warning,
        price: price,
        discount: discount,
        tax: tax,
        category: category,
        brand: brand,
        description: description,
        specification: specification,
        is_have_variant: 1,
        unit: 1,
        weight: 1,
        returning_date: 1,

      };

      if (!productNameError
        && !skuError
        && !priceError
        && !brandError
        && !categoryError
        && !stockCountError
        && !taxError
        && !weightError
        && !returningDateError
      ) {
        const productcreateResponse = await axios.post('https://folcon.voguefit.in/admin/create-product', createPayload, config);
        if (productcreateResponse.data.status === "success") {
          onSuccess(productcreateResponse.data.data.ID);
          toast.success('Product created successfully!');
        } else {
          toast.error('Product creation failed. Please try again.');
        }
      }

    } catch (error) {
      console.error('Error creating product:', error);
      toast.error('Product creation failed. Please try again.');
    }
  };
  return (
    <>
      <form className="product-form">
        {/* first row **************************** */}
        <div className="form-row">
          {/* Product name */}
          <div className="form-group">
            <TextField
              id="product_name"
              label="Product Name*"
              variant="outlined"
              placeholder="Type here"
              value={productformData.product_name}
              onChange={handleChange}
              error={Boolean(validationErrors.product_name)}
              helperText={validationErrors.product_name}
              fullWidth
              InputProps={{
                style: { backgroundColor: 'white' },
              }}
            />
          </div>
          {/* {Slug} */}
          <div className="form-group">
            <TextField
              id="slug"
              label="Slug"
              variant="outlined"
              placeholder="Type here"
              name="slug"
              value={productformData.slug}
              onChange={handleChange}
              error={Boolean(validationErrors.slug)}
              helperText={validationErrors.slug}
              InputProps={{
                readOnly: true,  // Makes the TextField read-only
                style: { backgroundColor: 'white' },
              }}
              fullWidth
            />
          </div>
        </div>

        {/* {second row ***********************} */}
        {/* Description */}
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="description" className="form-label">Description</label>
            <div className="editor-wrapper">
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                config={{
                  toolbar: [
                    'heading', '|',
                    'bold', 'italic', 'blockQuote', '|',
                    'numberedList', 'bulletedList', '|',
                    'undo', 'redo'
                  ],
                }}
              />
            </div>
            <CustomValidationError error={validationErrors.description} />
          </div>


          {/* Specification */}
          <div className="form-group">
            <label htmlFor="specification" className="form-label">Specification</label>
            {/* <textarea id="specification" className="form-input specification" /> */}
            <CKEditor
              editor={ClassicEditor}
              data={specification}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSpecification(data);
              }}
              config={{
                toolbar: [
                  'heading', '|',
                  'bold', 'italic', 'blockQuote', '|',
                  'numberedList', 'bulletedList', '|',
                  'undo', 'redo'
                ],
              }}
            />
            {/* <Input type="text" placeholder="Enter specification" className="form-control" id="specification" value={productformData.specification} onChange={handleChange} /> */}
          </div>
        </div>
        {/* {third row } *******************88*/}
        <div className="form-row">
          {/* Brand */}
          <div className="form-group">
            <label htmlFor="brand" className="form-label">Brand</label>
            <Select
              id="brand"
              options={brandList?.map(brand => ({
                value: brand.id,
                label: brand.brand_name.toUpperCase()
              }))}
              value={brandList?.find(brand => brand.id === productformData.brand) ? {
                value: productformData.brand,
                label: brandList?.find(brand => brand.id === productformData.brand).brand_name.toUpperCase()
              } : null}
              onChange={(selectedOption) => {
                setProductFormData({
                  ...productformData,
                  brand: selectedOption ? selectedOption.value : ''
                });
              }}
              placeholder="Select brand"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999, // Adjust as needed
                }),
              }}
            />
            <CustomValidationError error={validationErrors.brand} />
          </div>

          {/* Category */}
          <div className="form-group">
            <label htmlFor="category" className="form-label">Category</label>
            <Select
              id="category"
              options={categoryList?.map(category => ({
                value: category.id,
                label: category.category_name.toUpperCase()
              }))}
              value={categoryList?.find(category => category.id === productformData.category) ? {
                value: productformData.category,
                label: categoryList?.find(category => category.id === productformData.category).category_name.toUpperCase()
              } : null}
              onChange={(selectedOption) => {
                setProductFormData({
                  ...productformData,
                  category: selectedOption ? selectedOption.value : ''
                });
              }}
              placeholder="Select Category"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999, // Adjust as needed
                }),
              }}
            />
            <CustomValidationError error={validationErrors.category} />
          </div>


          {/* Discount */}
          <div className="form-group">
            <label htmlFor="discount" className="form-label">Discount</label>
            <Select
              id="discount"
              options={discountList?.map(discount => ({
                value: discount.id,
                label: discount.name.toUpperCase()
              }))}
              value={discountList?.find(discount => discount.id === productformData.discount) ? {
                value: productformData.discount,
                label: discountList?.find(discount => discount.id === productformData.discount).name.toUpperCase()
              } : null}
              onChange={(selectedOption) => {
                setProductFormData({
                  ...productformData,
                  discount: selectedOption ? selectedOption.value : ''
                });
              }}
              placeholder="Select Discount"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999, // Adjust as needed
                }),
              }}
            />
          </div>

          {/* Tax */}
          <div className="form-group">
            <label htmlFor="tax" className="form-label">Tax</label>
            <Select
              id="tax"
              options={taxList?.map(tax => ({
                value: tax.id,
                label: tax.label.toUpperCase()
              }))}
              value={taxList?.find(tax => tax.id === productformData.tax) ? {
                value: productformData.tax,
                label: taxList?.find(tax => tax.id === productformData.tax).label.toUpperCase()
              } : null}
              onChange={(selectedOption) => {
                setProductFormData({
                  ...productformData,
                  tax: selectedOption ? selectedOption.value : ''
                });
              }}
              placeholder="Select Tax"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999, // Adjust as needed
                }),
              }}
            />
            <CustomValidationError error={validationErrors.tax} />
          </div>


        </div>

        {/* fourth row ***************************** */}

        <div className="form-row">


          {/* {Maximun price} */}
          <div className="form-group">
            <TextField
              id="minimum_stock_warning"
              label="Minimum Stock Warning*"
              variant="outlined"
              placeholder="Type here"
              className="form-input min-stock-warning"
              value={productformData.minimum_stock_warning}
              onChange={handleChange}
              error={Boolean(validationErrors.minimum_stock_warning)}
              helperText={validationErrors.minimum_stock_warning}
              InputProps={{
                style: { backgroundColor: 'white' },
              }}
            />

          </div>

          {/* Price */}
          <div className="form-group">
            <TextField
              id="price"
              label="Price"
              variant="outlined"
              placeholder="Type here"
              className="form-input price"
              value={productformData.price}
              onChange={handleChange}
              error={Boolean(validationErrors.price)}
              helperText={validationErrors.price}
              InputProps={{
                style: { backgroundColor: 'white' },
              }}
              required
            />
          </div>

          {/* SKU */}
          <div className="form-group">
            <TextField
              id="sku"
              label="SKU"
              variant="outlined"
              placeholder="Type here"
              className="form-input sku"
              value={productformData.sku}
              onChange={handleChange}
              error={Boolean(validationErrors.sku)}
              helperText={validationErrors.sku}
              InputProps={{
                style: { backgroundColor: 'white' },
              }}
            />
          </div>
          {/* Quantity */}
          <div className="form-group">
            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              placeholder="Type here"
              name="quantity"
              className="form-input quantity"
              value={productformData.quantity}
              onChange={handleChange}
              disabled={productformData.is_pre_order === 1}
              error={Boolean(validationErrors.quantity)}
              helperText={validationErrors.quantity}
              InputProps={{
                style: { backgroundColor: 'white' },
              }}
              required
            />
            {/* Remove CustomValidationError since TextField handles errors with error and helperText props */}
          </div>
        </div>


        {/* {Fifth row ***************************************} */}
        <div className="form-row">
          {/* Thumbnail Image */}
          <div className="form-group row-5">
            <label htmlFor="thumbnailImage" className="form-label">Thumbnail Image</label>
            {previewImage ? (
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    width: "70px",
                    height: "70px",
                  }}
                />
                <div>
                  <button
                    type="button"
                    onClick={handleDeletePreview}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "150px",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </div>
            ) : (

              <img src={folder} width={90} height={90} />
            )}
            <button
              type="button"
              className="btn btn-primary  btn-block"
              onClick={openModal}
            >
              Select Image
            </button>
          </div>
          {/* 
        Status */}
          <div className="form-group">
            <label htmlFor="status" className="form-label">Status</label>
            <div className="form-check form-switch">
              <InputCheckbox className="form-check-input" type="checkbox" id="toggle" checked={productformData.is_active === 1} onChange={handleToggleChange} defaultChecked={true} />
            </div>
            {/* <input type="checkbox" id="status" className="form-input status" /> */}
          </div>
          <div className="form-group">
            <label htmlFor="preOrder" className="form-label">Pre Order</label>
            <div className="form-check form-switch">
              <InputCheckbox className="form-check-input" type="checkbox" id="toggle" checked={productformData.is_pre_order === 1} onChange={handlePreOrderChange}
                defaultChecked={false}
              />
            </div>
            {/* <input type="checkbox" id="preOrder" className="form-input pre-order" /> */}
          </div>

        </div>

        <div className="form-row d-flex justify-content-end">
          <button type="submit" onClick={handleSubmit}>
            <FontAwesomeIcon icon={faCirclePlus} />
            &nbsp;
            Create Form
          </button>
        </div>
      </form>
      <SingleImageSelect
        isOpen={modalIsOpen}
        handleClose={closeModal}
        handleImageClick={handleImageClick}
        selectedImages={selectedImages}
        imageUrl={imageUrl}
        token={token}
        setImageUrl={setImageUrl}

      />
    </>


  );
};

export default ProductForm;

