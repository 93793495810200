import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadImage from "../../../assets/images/defimg.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { MediaList } from "../../../service/apiService";
import { useNavigate } from "react-router-dom";
import { CustomValidationError } from "../../../Helper/Helper";
import { ValidateClasificationName } from "../../../validation/CustomValidation";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

const ClassificationCreate = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    classification_name: "",
    slug: "",
    image_path: "",
    is_active: 1,
  });


  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const AppUrl = "https://folcon.voguefit.in/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [ClassificationImage, setClassificationImage] = useState(null);
  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter((i) => i !== index));
      }
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setClassificationImage(imageUrl[index]);
      closeModal();
    }
  };

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      // if(response.length > 0){
      response.then((response) => {
        const imageUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });
      // }
    }
  }, [token]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
      slug: generateSlug(value),
    });
  };

  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setFormData({
      ...formData,
      is_active: activeValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("admin_token");

    const ClassificationNameError = ValidateClasificationName(formData.classification_name);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },

    };
    setValidationErrors({
      classification_name: ClassificationNameError,
    });
    console.log("formData", formData);
    try {
      if (!formData.classification_name) {

        toast.error("Please Fill The Form Data");
        return;
      }

      const createPayload = {
        ...formData,
        image_path: ClassificationImage,
        classification_name: formData.classification_name,
      };

      const createResponse = await axios.post(
        "https://folcon.voguefit.in/admin/create-classification",
        createPayload,
        config
      );

      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        toast.success("Classification created successfully!");
        setFormData({
          classification_name: "",
          slug: "",
          is_active: 1,
        });
        navigate("/admin/classifications");
      } else {
        toast.error("Classification creation failed. Please try again.");
      }
    } catch (error) {
      console.error("Error creating Classification:", error);
      toast.error("Classification creation failed. Please try again.");
    }
  };

  const [validationErrors, setValidationErrors] = useState({
    classification_name: "",
  });

  return (
    <main id="main" className="main">
      <AdminHeader />
      <SideBar />
      <div className="pagetitle">
        <h1>Classification Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Classification</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <label htmlFor="name" className="col-sm-2 col-form-label">
                      Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        id="classification_name"
                        type="text"
                        value={formData.name}
                        placeholder="Type here"
                        onChange={handleChange}
                        className="form-control"
                      />
                      <CustomValidationError error={validationErrors.classification_name} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="slug" className="col-sm-2 col-form-label">
                      Slug
                    </label>
                    <div className="col-sm-8">
                      <input
                        id="slug"
                        type="text"
                        value={formData.slug}
                        placeholder="Type here"
                        onChange={handleChange}
                        className="form-control"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">
                      Status
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="toggle"
                        checked={formData.is_active === 1}
                        onChange={handleToggleChange}
                      />
                      <label htmlFor="toggle" className="form-check-label">
                        {" "}
                        {formData.is_active === 1 ? "Active" : "Inactive"}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label>Preview</label>
                    <br />
                    {previewImage ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={previewImage}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            width: "200px",
                            height: "200px",
                          }}
                        />

                        <button
                          type="button"
                          onClick={handleDeletePreview}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ) : (
                      <img src={UploadImage} alt="Alt" />
                    )}
                    <br></br>
                    <button
                      type="button"
                      className="btn btn-primary mt-2 btn-sm btn-block"
                      onClick={openModal}
                    >
                      Select Image
                    </button>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <SingleImageSelect
            isOpen={modalIsOpen}
            handleClose={closeModal}
            handleImageClick={handleImageClick}
            selectedImages={selectedImages}
            imageUrl={imageUrl}
            token={token}
            setImageUrl={setImageUrl}
          />
        </div>
      </section>
      <AdminFooter />
    </main>
  );
};

export default ClassificationCreate;
