import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";






const MediaStore = () => {
    const allowedFileExtensions = ['jpg', 'jpeg', 'png', 'gif', 'mp4'];
    const allowedExtensionsString = allowedFileExtensions.join(', ');
    const maxFileSize = 5242880; // 5MB in bytes
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const navigate = useNavigate();




    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            // const extension = file.name.split('.').pop().toLowerCase();
            // if (!allowedFileExtensions.includes(extension)) {
            //     setErrorMessage(`File type not allowed. Please upload files with extensions: ${allowedExtensionsString}`);
            //     return;
            // }
            // if (file.size > maxFileSize) {
            //     setErrorMessage('File size exceeds the limit (5MB). Please upload a smaller file.');
            //     return;
            // }
            setUploadedFile(file);
            setErrorMessage('');
        }
    };

    const handleUploadButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-CSRF-Token': csrfToken,
        },
    };

    const handleStoreImageToDB = async () => {
        try {
            const formData = new FormData();
            formData.append('file_name', uploadedFile);
            const response = await axios.post('https://folcon.voguefit.in/admin/media-upload', formData, config);
            console.log('Media uploaded successfully:', response.data);
            setUploadedFile(null);
            toast.success('Media uploaded successfully!');
            navigate('/admin/media');

        } catch (error) {
            console.error('Error uploading media:', error);
            setErrorMessage('Error uploading media. Please try again.');
            toast.error('Error uploading media. Please try again.');
        }
    };

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Media Create</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Media</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>
            </div>
            <section className="content-main">
                <div className="col-12">
                    <div className="card card-height">
                        <div className="card-header">
                            <h5>Media Upload</h5>
                            <div className="form-group">
                                <div className="col-md-8">
                                    <span className="badge bg-danger">Note!</span> Allowed File Extensions: {allowedExtensionsString}
                                </div>
                            </div>
                        </div>
                        <div className="card-block file-upload-card-block">
                            <form className="dropzone h-100 create-image-form">
                                <div className="fallback">
                                    <input id="fileInput" name="file" type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
                                    <br />
                                    <button type="button" onClick={handleUploadButtonClick} className="btn btn-primary">Choose Image</button>
                                    <br /> <br />
                                </div>
                            </form>
                            {errorMessage && <div className="text-danger">{errorMessage}</div>}
                            {uploadedFile && (
                                <div>
                                    {uploadedFile.type.startsWith('image/') ? (
                                        <img src={URL.createObjectURL(uploadedFile)} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                                    ) : uploadedFile.type.startsWith('video/') ? (
                                        <video controls style={{ maxWidth: '100%', maxHeight: '400px' }}>
                                            <source src={URL.createObjectURL(uploadedFile)} type={uploadedFile.type} />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : null}
                                    <div>File uploaded: {uploadedFile.name}</div>
                                </div>
                            )}
                            <br /> <br />
                            {uploadedFile && <button type="button" onClick={handleStoreImageToDB} className="btn btn-success">Upload File</button>}

                        </div>
                    </div>
                </div>
            </section>
        </main>


    )
}

export default MediaStore;